import React from "react";
import {Form} from "../../XLibItems";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {XFormBaseModif} from "@michalrakus/x-react-web-lib/XFormBaseModif";
import {
    XFormColumn,
    XFormDataTable2
} from "@michalrakus/x-react-web-lib/XFormDataTable2";
import {EnumEnum, XGroupByType} from "../../common/enums";
import {XObject} from "@michalrakus/x-react-web-lib/XObject";
import {XFormHeader} from "@michalrakus/x-react-web-lib/XFormHeader";
import {XDropdownEnum} from "../user/XDropdownEnum";
import {XAutoComplete} from "@michalrakus/x-react-web-lib/XAutoComplete";
import {XsGroupByField} from "../../model/statistics/xs-group-by-field.entity";
import {XErrors} from "@michalrakus/x-react-web-lib/XErrors";
import {XsIntervalSetBrowse} from "./XsIntervalSetBrowse";
import {XsIntervalSetForm} from "./XsIntervalSetForm";
import {XEnumEnumBrowse} from "../user/XEnumEnumBrowse";
import {XEnumEnumForm} from "../user/XEnumEnumForm";
import {Utils, XsAccessType} from "../../Utils";

@Form("XsGroupByField")
export class XsGroupByFieldForm extends XFormBaseModif {

    constructor(props: any) {
        super(props);

        // join xEnumEnum and xsIntervalSet because their components are not present during creating component XsGroupByFieldForm
        this.addField("xEnumEnum.name");
        this.addField("xsIntervalSet.label");
    }

    createNewObject(): XObject {
        return {xsGroupByFieldByEntityList: [], version: 0};
    }

    // pomocna metodka
    private getXsGroupByField(): XsGroupByField | null {
        return this.state.object;
    }

    formReadOnly(object: XObject, field: string): boolean {
        return !Utils.xsUserHasAccess(XsAccessType.full);
    }

    private isFieldType(xGroupByType: XGroupByType): boolean {
        return this.getXsGroupByField()?.fieldType?.code === xGroupByType;
    }

    async validate(object: XsGroupByField): Promise<XErrors> {
        const errors: XErrors = {};
        if (this.isFieldType(XGroupByType.entityEnum)) {
            if (!object.entity) {
                errors.entity = "Field is required.";
            }
            if (!object.displayField) {
                errors.displayField = "Field is required.";
            }
        }
        else if (this.isFieldType(XGroupByType.xEnum)) {
            if (!object.xEnumEnum) {
                errors.xEnumEnum = "Field is required.";
            }
        }
        else if (this.isFieldType(XGroupByType.age) || this.isFieldType(XGroupByType.intervalSet)) {
            if (!object.xsIntervalSet) {
                errors.xsIntervalSet = "Field is required.";
            }
        }
        return errors;
    }

    render() {
        return (
            <div>
                <XFormHeader form={this} label="Group by field"/>
                <div className="x-form-row">
                    <div className="x-form-col">
                        <XInputDecimal form={this} field="id" label="ID" readOnly={true}/>
                        <XInputDate form={this} field="modifDate" label="Modified at" readOnly={true}/>
                        <XInputText form={this} field="modifXUser.name" label="Modified by" inputStyle={{width:'12.5rem'}}/>
                        <XInputText form={this} field="label" label="Label" inputStyle={{width:'20rem'}}/>
                        <XInputDecimal form={this} field="width" label="Width" inputStyle={{width:'5rem'}}/>
                        <XDropdownEnum form={this} assocField="fieldType" label="Field type" enumEnumCode={EnumEnum.xGroupByType}/>
                        {this.isFieldType(XGroupByType.entityEnum) ? <XInputText form={this} field="entity" label="Entity *" inputStyle={{width:'15rem'}}/> : null}
                        {this.isFieldType(XGroupByType.entityEnum) ? <XInputText form={this} field="displayField" label="Display field *" inputStyle={{width:'15rem'}}/> : null}
                        {this.isFieldType(XGroupByType.xEnum) ?
                            <XAutoComplete form={this} assocField="xEnumEnum" displayField="name" label="Enum *"
                                           searchBrowse={<XEnumEnumBrowse/>}/> : null}{/* assocForm={<XEnumEnumForm/>} is not used because user with low access type can edit XEnumEnum - we should open form in readOnly mode */}
                        {this.isFieldType(XGroupByType.entityEnum) || this.isFieldType(XGroupByType.xEnum) ? <XInputText form={this} field="filter" label="Filter" inputStyle={{width:'20rem'}}/> : null}
                        {this.isFieldType(XGroupByType.age) || this.isFieldType(XGroupByType.intervalSet) ?
                            <XAutoComplete form={this} assocField="xsIntervalSet" displayField="label" label="Interval set *"
                                           searchBrowse={<XsIntervalSetBrowse/>} assocForm={<XsIntervalSetForm/>}/> : null}
                        <XFormDataTable2 form={this} assocField="xsGroupByFieldByEntityList" label="Fields by entity">
                            <XFormColumn field="id" header="ID" readOnly={true} width="4rem"/>
                            <XFormColumn field="entity" header="Entity" width="15rem"/>
                            <XFormColumn field="field" header="Field (path)" width="20rem"/>
                        </XFormDataTable2>
                    </div>
                </div>
                <XFormFooter form={this}/>
            </div>
        );
    }
}
