import {XLazyColumn, XLazyDataTable} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import React from "react";
import {VykazStreetworkForm} from "./VykazStreetworkForm";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";
import {Utils} from "../../Utils";
import {EnumEnum} from "../../common/enums";
import {VykazStreetwork} from "../../model/zapisy/vykaz-streetwork.entity";
import {VykazStreetworkHlavickaForm} from "./VykazStreetworkHlavickaForm";
import {XButton} from "@michalrakus/x-react-web-lib/XButton";

export const VykazStreetworkBrowse = (props: {}) => {

    const onAddRow = () => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        (props as any).openForm(<VykazStreetworkHlavickaForm/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        (props as any).openForm(<VykazStreetworkHlavickaForm id={selectedRow.id}/>);
    }

    // tieto 2 funkcie (velky formular aj so zapismi) tu mozno budu docasne

    const onPridatSoZapismi = () => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        (props as any).openForm(<VykazStreetworkForm/>);
    }

    const onUpravitSoZapismi = (selectedRow: VykazStreetwork) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        (props as any).openForm(<VykazStreetworkForm id={selectedRow.id}/>);
    }

    return (
        <XLazyDataTable entity="VykazStreetwork" label="Denník" sortField={[{field: "datum", order: -1}, {field: "id", order: -1}]} rows={30} multilineSwitch={true}
                        onAddRow={onAddRow} onEdit={onEdit} removeRow={true}
                        appButtons={[<XButton key="pridat-so-zapismi" label="Pridať aj so zápismi" onClick={onPridatSoZapismi}/>]}
                        appButtonsForRow={[{key: "upravit-so-zapismi", label: "Upraviť aj so zápismi", onClick: onUpravitSoZapismi}]}
                        displayed={(props as any).displayed}>
            <XLazyColumn field="id" header="ID" width="5rem"/>
            <XLazyColumn field="datum" header="Dátum" betweenFilter="column"/>
            <XLazyColumn field="tim.name" header="Tím" width="6rem" dropdownInFilter={true} dropdownFilter={Utils.enumFilter(EnumEnum.streetworkTim)}/>
            <XLazyColumn field="vykazXUserList.xUser.name" header="Pracovníci" width="15rem"/>
            <XLazyColumn field="priebehSluzby" header="Priebeh služby" width="60rem" contentType="html"/>
        </XLazyDataTable>
    );
}
