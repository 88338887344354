import {XBrowseProps, XLazyColumn, XLazyDataTable} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import React from "react";
import {Utils} from "../../Utils";
import {ZapisForm} from "./ZapisForm";
import {EnumEnum, SluzbaEnum} from "../../common/enums";
import {KlientSluzbaForm} from "../klient/KlientSluzbaForm";
import {DataTableFilterMeta} from "primereact/datatable";
import {XButton} from "@michalrakus/x-react-web-lib/XButton";

export const ZapisBrowse = (props: XBrowseProps & {filters?: DataTableFilterMeta;}) => {

    const onAddRow = () => {

        if (!Utils.getCurrentSluzba()) {
            alert("Vyberte najprv službu.");
            return;
        }

        // if (Utils.getCurrentSluzba()?.kod === SluzbaEnum.streetwork) {
        //     alert("Zápis pre streetwork je dovolené vytvoriť len cez voľbu Zápisy - streetwork.");
        //     return;
        // }

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<ZapisForm/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<ZapisForm id={selectedRow.id}/>);
    }

    const sluzbaEnum: SluzbaEnum | undefined = Utils.getCurrentSluzba()?.kod as SluzbaEnum;

    const [fieldList, minLength]: [string[], number] = Utils.klientSluzbaFieldListForAutoComplete();

    return (
        <div>
            <XLazyDataTable entity="Zapis" label={Utils.isSluzbaNoclaharen() ? "Ošetrenia" : "Zápisy"} sortField={[{field: "datum", order: -1}, {field: "id", order: -1}]} rows={30}
                            filters={props.filters} customFilter={Utils.filterCurrentSluzba()}
                            onAddRow={onAddRow} onEdit={onEdit} removeRow={true}
                            appButtons={props.filters ? <XButton key="naspet" label="Naspäť" onClick={() => (props as any).openForm(null)}/> : undefined} /* ak sme prisli z VykazStreetworkHlavickaForm, zobrazime button Naspet */
                            displayed={props.displayed} multilineSwitch={true}>
                <XLazyColumn field="id" header="ID" width="5rem"/>
                <XLazyColumn field="datum" header="Dátum" betweenFilter="column"/>
                <XLazyColumn field="vykazStreetwork.tim.name" header="Tím" width="6rem" columnViewStatus={sluzbaEnum === SluzbaEnum.streetwork} dropdownInFilter={true} dropdownFilter={Utils.enumFilter(EnumEnum.streetworkTim)}/>
                <XLazyColumn field="klientSluzba.klient.menoPriezviskoPrezyvka" header="Klient" width="12rem"
                             autoComplete={{
                                 assocField: "klientSluzba", field: fieldList, filter: Utils.filterCurrentSluzba(), valueForm: <KlientSluzbaForm/>,
                                 lazyLoadMaxRows: 15, minLength: minLength, scrollHeight: "50rem"
                             }}/>
                <XLazyColumn field="vseobecnyZapis" header="Zápis" width="70rem" contentType="html"/>
                <XLazyColumn field="atributy" fieldSetId="zapis" header="Klikačky" width="20rem"/>
                <XLazyColumn field="lujzaPoradProjekt.name" header="Projekt" width="9rem" columnViewStatus={sluzbaEnum === SluzbaEnum.lujzaPoradenstvo} dropdownInFilter={true} dropdownFilter={Utils.enumFilter(EnumEnum.lujzaPoradProjekt)}/>
                <XLazyColumn field="vincentPoradProjekt.name" header="Projekt" width="10rem" columnViewStatus={sluzbaEnum === SluzbaEnum.vincentPoradenstvo} dropdownInFilter={true} dropdownFilter={Utils.enumFilter(EnumEnum.vincentPoradProjekt)}/>
            </XLazyDataTable>
        </div>
    );
}
