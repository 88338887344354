import React, {useState} from "react";
import {
    XBrowseProps,
    XLazyColumn,
    XLazyDataTable
} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import {XrReport} from "../../model/reports/xr-report.entity";
import {XrReportForm} from "./XrReportForm";
import {XRunReportDialog, XRunReportDialogState} from "./XRunReportDialog";
import {Utils, XsAccessType} from "../../Utils";

export const XrReportBrowse = (props: XBrowseProps) => {

    const [xRunReportDialogState, setXRunReportDialogState] = useState<XRunReportDialogState>({opened: false});

    const onAddRow = () => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<XrReportForm/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<XrReportForm id={selectedRow.id}/>);
    }

    const onRunReport = async (selectedRow: XrReport) => {
        setXRunReportDialogState({
            opened: true,
            xrReportId: selectedRow.id,
            onHideDialog: () => setXRunReportDialogState({opened: false})
        });
    }

    const canAddRemoveRow = Utils.xsUserHasAccess(XsAccessType.usageOnly);

    return (
        <div>
            <XLazyDataTable entity="XrReport" label="Reports" rows={30} sortField="label"
                            onAddRow={canAddRemoveRow ? onAddRow : undefined} onEdit={onEdit} removeRow={canAddRemoveRow}
                            appButtonsForRow={[{key: "button-run-report", label: "Run report", onClick: onRunReport}]}
                            displayed={props.displayed} multilineSwitch={true}>
                <XLazyColumn field="id" header="ID" width="5rem"/>
                <XLazyColumn field="label" header="Label" width="20rem"/>
                <XLazyColumn field="entity" header="Entity" width="15rem"/>
                <XLazyColumn field="xrReportColumnList.header" header="Column headers" width="20rem"/>
                <XLazyColumn field="xrReportColumnList.field" header="Column fields" width="15rem"/>
            </XLazyDataTable>
            <XRunReportDialog dialogState={xRunReportDialogState}/>
        </div>
    );
}
