import React, {useRef} from "react";
import {IPostgresInterval, OperationType, XUtils, XViewStatus} from "@michalrakus/x-react-web-lib/XUtils";
import {Zapis} from "../../model/zapisy/zapis.entity";
import {XAutoCompleteBase} from "@michalrakus/x-react-web-lib/XAutoCompleteBase";
import {XButtonIconMedium} from "@michalrakus/x-react-web-lib/XButtonIconMedium";
import {XFieldElemProps, XFieldMeta, XFieldProp, XFieldSetBase, XFieldSetValues, XFieldType} from "./XFieldSetBase";
import {Panel} from "primereact/panel";
import {Utils} from "../../Utils";
import {XInputIntervalBase} from "@michalrakus/x-react-web-lib/XInputIntervalBase";
import {XUserDePaul} from "../../model/user/x-user-de-paul.entity";
import {SluzbaRolaPrava} from "../../model/user/sluzba-rola-prava.entity";
import {PravaFieldPropEdit} from "./PravaFieldPropEdit";
import {XSimpleFieldPropEdit} from "./XSimpleFieldPropEdit";
import {VykazStreetwork} from "../../model/zapisy/vykaz-streetwork.entity";
import {XErrors} from "@michalrakus/x-react-web-lib/XErrors";
import {VykazXUser} from "../../model/zapisy/vykaz-x-user.entity";
import {XEditorBase} from "@michalrakus/x-react-web-lib/XEditorBase";
import {KlientSluzbaAutoComplete} from "../klient/KlientSluzbaAutoComplete";
import {XFormBase} from "@michalrakus/x-react-web-lib/XFormBase";
import {KlientSluzba} from "../../model/klient/klient-sluzba.entity";
import {XInputTextareaBase} from "@michalrakus/x-react-web-lib/XInputTextareaBase";
import {HistoriaFieldPropEdit} from "./HistoriaFieldPropEdit";
import {AtributInfo, AtributyHistoriaRequest, AtributyHistoriaResponse} from "../../common/zapisy-api";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";
import {EnumEnum, SluzbaEnum, SluzbaRolaPravaKod} from "../../common/enums";
import {dateAsUI, dateFromModel} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {XDropdownForEntity} from "@michalrakus/x-react-web-lib/XDropdownForEntity";
import {XEnum} from "../../model/user/x-enum.entity";

// spolocny komponent pouzivany vo VykazStreetworkForm a v ZapisForm
// zoznamy pre suggestions aj ine zoznamy (klientList a pod.) su vytiahnute vyssie len koli performance
// ale v buducnosti planujem zamenit tieto zoznamy za lazy nacitavanie suggestions

enum FieldPropId {
    sluzbaRolaPrava = "sluzbaRolaPrava",
    specPorad = "specPorad",
    psychPorad = "psychPorad",
    historia = "historia"
}

const fieldProps: XFieldProp[] = [
    {
        id: FieldPropId.sluzbaRolaPrava,
        label: "Viditeľnosť/prístup podľa služby/roly",
        fieldPropEdit: <PravaFieldPropEdit/>
    },
    // specPorad a psychPorad - dame 2 uplne samostatne propertiesy, nech mozu mat nezavislu stromovu strukturu
    {
        id: FieldPropId.specPorad,
        label: "Špecializované poradenstvo",
        fieldPropEdit: <XSimpleFieldPropEdit label="Áno/nie"/>
    },
    {
        id: FieldPropId.psychPorad,
        label: "Psychologické poradenstvo",
        fieldPropEdit: <XSimpleFieldPropEdit label="Áno/nie"/>
    },
    {
        id: FieldPropId.historia,
        label: "Kontrola, či bol atribút zakliknutý/použitý v blízkej minulosti",
        fieldPropEdit: <HistoriaFieldPropEdit/>
    }
];

export const ZapisPanel = (props: {
    vykazStreetwork: VykazStreetwork | null | undefined
    zapis: Zapis;
    onChangeZapis: (callback?: () => void) => void;
    onChangeKlientSluzba?: (klientSluzba: KlientSluzba | null) => void;
    removeZapis?: (zapis: Zapis) => void;
    userSpecPoradList: XUserDePaul[];
    userPsychPoradList: XUserDePaul[];
    idSluzbaRolaPravaMap: Map<number, SluzbaRolaPrava> | undefined;
    kodSluzbaRolaPravaMap: Map<string, SluzbaRolaPrava> | undefined;
    onEditFieldChange: () => void;
}) => {

    // premenne platne pre cely component (obdoba member premennych v class-e)
    let zapis: Zapis = props.zapis;

    const xInputTextareaBasePoznamkaOsetrovnaRef = useRef<XInputTextareaBase>(null);

    const getPoradXUserSuggestions = (vykazStreetwork: VykazStreetwork | null | undefined, userPoradList: XUserDePaul[]): XUserDePaul[] => {
        let xUserList: XUserDePaul[] = userPoradList;
        if (vykazStreetwork) {
            // ponukneme userov ktori su zadani na tomto vykaze a zaroven maju rolu specPoradPracovnik/psychPoradPracovnik
            xUserList = XUtilsCommon.arrayIntersect<XUserDePaul>(xUserList, vykazStreetwork.vykazXUserList.map((vykazXUser: VykazXUser) => vykazXUser.xUser), 'id');
        }
        return xUserList;
    }

    const onChangeKlientSluzba = async (zapis: Zapis, klientSluzba: KlientSluzba | null) => {
        // zapiseme do modelu
        zapis.klientSluzba = klientSluzba!;

        // pre lujzaPorad a vincentPorad nastavime projekt vzdy podla projektu na klientovi (ak neni na klientovi nastaveny, tak ho musi klient nastavit)
        if (zapis.sluzba.kod === SluzbaEnum.lujzaPoradenstvo || zapis.sluzba.kod === SluzbaEnum.vincentPoradenstvo) {
            zapis.lujzaPoradProjekt = klientSluzba?.lujzaPoradProjekt ?? null;
            zapis.vincentPoradProjekt = klientSluzba?.vincentPoradProjekt ?? null;
        }

        // zmenil sa klient, prepocitame historiu atributov
        // ak by to dlho trvalo, asi sa da zavolat metodu asynchronne (bez await), len potom treba po jej skonceni volat setState formulara
        await ZapisPanel.vytvorAtributyHistoria(zapis);

        // zavolame react metodu this.setState (aby sa zmena prejavila vo formulari)
        // ak mame ref na textarea poznamka (Alzbeta osetrovna), tak este (manualne) zavolame metodu autoResize komponentu XInputTextarea - metoda nastavi spravnu vysku textarea (podla poctu riadkov)
        props.onChangeZapis(xInputTextareaBasePoznamkaOsetrovnaRef.current ? () => xInputTextareaBasePoznamkaOsetrovnaRef.current!.autoResize() : undefined);

        if (props.onChangeKlientSluzba) {
            props.onChangeKlientSluzba(klientSluzba);
        }
    }

    const onErrorChangeKlientSluzba = (zapis: Zapis, error: string | undefined) => {
        zapis.klientSluzbaError = error; // odlozime/vynulujeme si error; na backend sa nedostane, lebo validacia zbehne len ak je klientError undefined
    }

    const onFieldChangeZapis = (zapis: Zapis, field: string, value: any) => {
        // zapiseme do json fieldu
        (zapis.atributy as XFieldSetValues)[field] = value;
        // zavolame react metodu this.setState (aby sa zmena prejavila vo formulari)
        props.onChangeZapis();
    }

    const fieldViewStatusZapis = (zapis: Zapis, xFieldMeta: XFieldMeta): XViewStatus => {
        let viewStatus: XViewStatus = XViewStatus.Hidden; // default
        // field zobrazime, len ak ma aktualny uzivatel prava vidiet dany field
        if (props.idSluzbaRolaPravaMap) { // -> ak uz zbehol componentDidMount() a prava su nacitane
            const sluzbaRolaPrava: SluzbaRolaPrava | undefined = PravaFieldPropEdit.getFieldPropValue(xFieldMeta, FieldPropId.sluzbaRolaPrava, props.idSluzbaRolaPravaMap);
            if (sluzbaRolaPrava) {
                viewStatus = Utils.userPravoViewStatus(sluzbaRolaPrava);
            }
            else {
                // toto je specialna vynimka
                // ak nemame zadany objekt prav na root-e, tak default viewStatus = readWrite
                // je to len pomocka (nemusi to tu nutne byt), aby sme nemuseli zadavat objekt prav, ktory by aj tak musel mat nastavene readWrite na vsetky sluzby/role
                // (inac by sme "nepresli" cez hidden viewStatus na child fieldy)
                if (xFieldMeta.type === XFieldType.rootField) {
                    viewStatus = XViewStatus.ReadWrite;
                }
            }
        }
        return viewStatus;
    }

    const fieldElemPropsZapis = (zapis: Zapis, xFieldMeta: XFieldMeta): XFieldElemProps | undefined => {
        let label: string | undefined = undefined; // default
        let className: string | undefined = undefined; // default
        if (!XFieldSetBase.isFieldGroup(xFieldMeta.type)) {
            const isSpecPorad: boolean = XSimpleFieldPropEdit.getFieldPropValue(xFieldMeta, FieldPropId.specPorad);
            const isPsychPorad: boolean = XSimpleFieldPropEdit.getFieldPropValue(xFieldMeta, FieldPropId.psychPorad);
            if (isSpecPorad && !isPsychPorad) {
                className = 'bg-indigo-100';
            }
            else if (!isSpecPorad && isPsychPorad) {
                className = 'bg-blue-100';
            }
            else if (isSpecPorad && isPsychPorad) {
                className = 'bg-pink-100';
            }

            const pocetDni: number | undefined = HistoriaFieldPropEdit.getFieldPropValue(xFieldMeta, FieldPropId.historia);
            if (pocetDni !== undefined) {
                if (zapis.atributyHistoria) {
                    // ak uz mame atributyHistoria vypocitane
                    const poslednyDatum: Date | null = dateFromModel(zapis.atributyHistoria[xFieldMeta.field]);
                    if (poslednyDatum) {
                        // vedla labelu zobrazime datum, kedy najblizsie dostane benefit
                        label = `${xFieldMeta.label} ${dateAsUI(XUtilsCommon.dateAddDays(poslednyDatum, pocetDni))}`;
                        className = 'bg-red-100';
                    }
                }
            }
        }
        return {label: label, className: className};
    }

    const onChangeSpecPoradXUser = (zapis: Zapis, xUser: XUserDePaul) => {
        // zapiseme do modelu
        zapis.specPoradXUser = xUser;
        // zavolame react metodu this.setState (aby sa zmena prejavila vo formulari)
        props.onChangeZapis();
    }

    const onErrorChangeSpecPoradXUser = (zapis: Zapis, error: string | undefined) => {
        zapis.specPoradXUserError = error; // odlozime/vynulujeme si error
    }

    const onChangeSpecPoradXUser2 = (zapis: Zapis, xUser: XUserDePaul) => {
        // zapiseme do modelu
        zapis.specPoradXUser2 = xUser;
        // zavolame react metodu this.setState (aby sa zmena prejavila vo formulari)
        props.onChangeZapis();
    }

    const onErrorChangeSpecPoradXUser2 = (zapis: Zapis, error: string | undefined) => {
        zapis.specPoradXUser2Error = error; // odlozime/vynulujeme si error
    }

    const onChangePsychPoradXUser = (zapis: Zapis, xUser: XUserDePaul) => {
        // zapiseme do modelu
        zapis.psychPoradXUser = xUser;
        // zavolame react metodu this.setState (aby sa zmena prejavila vo formulari)
        props.onChangeZapis();
    }

    const onErrorChangePsychPoradXUser = (zapis: Zapis, error: string | undefined) => {
        zapis.psychPoradXUserError = error; // odlozime/vynulujeme si error
    }

    const zistiViewStatusPrePravoKod = (pravoKod: SluzbaRolaPravaKod): XViewStatus => {
        let pravo: XViewStatus = XViewStatus.Hidden; // default
        if (props.kodSluzbaRolaPravaMap) {
            // ak uz mame map nacitany
            const sluzbaRolaPrava: SluzbaRolaPrava | undefined = props.kodSluzbaRolaPravaMap.get(pravoKod);
            if (sluzbaRolaPrava) {
                pravo = Utils.userPravoViewStatus(sluzbaRolaPrava);
            }
        }
        return pravo;
    }

    if (!zapis) {
        return null; // ak este nebol zapis nacitany (nezbehol componentDidMount)
    }

    // align-items-center - vo vertikalnom smere (posuva smerom dole do stredu)
    const osetrovna: boolean = zapis.sluzba.kod === SluzbaEnum.noclaharen || zapis.sluzba.kod === SluzbaEnum.alzbetaOsetrovna;
    const header: React.ReactNode =
        <div>
            <div className="flex justify-content-between align-items-center">
                <div className="flex align-items-center w-full">
                    <div className="mr-1">Klient</div>
                    <KlientSluzbaAutoComplete value={zapis.klientSluzba}
                                              onChange={(object: KlientSluzba | null, objectChange: OperationType) => onChangeKlientSluzba(zapis, object)}
                                              error={Utils.getError(XFormBase.getXRowTechData(zapis).errorMap, "klientSluzba")}
                                              onErrorChange={(error: (string | undefined)) => onErrorChangeKlientSluzba(zapis, error)}
                                              maxWidth="40rem" poznamkaOsetrovna={osetrovna}/>
                    {zapis.sluzba.kod === SluzbaEnum.lujzaPoradenstvo ? [
                        <div className="mx-1">Projekt</div>,
                        <XDropdownForEntity entity="XEnum" displayField="name" filter={Utils.enumFilter(EnumEnum.lujzaPoradProjekt)} sortField="enumOrder" isNotNull={true}
                                            value={zapis.lujzaPoradProjekt} onChange={(value: XEnum | null) => {zapis.lujzaPoradProjekt = value; props.onChangeZapis();}}/>
                        ] : null}
                    {zapis.sluzba.kod === SluzbaEnum.vincentPoradenstvo ? [
                        <div className="mx-1">Projekt</div>,
                        <XDropdownForEntity entity="XEnum" displayField="name" filter={Utils.enumFilter(EnumEnum.vincentPoradProjekt)} sortField="enumOrder" isNotNull={true}
                                            value={zapis.vincentPoradProjekt} onChange={(value: XEnum | null) => {zapis.vincentPoradProjekt = value; props.onChangeZapis();}}/>
                        ] : null}
                </div>
                {props.removeZapis ? <XButtonIconMedium icon="pi pi-times" onClick={() => props.removeZapis!(zapis)}/> : undefined}
            </div>
            {osetrovna ?
                <div className="flex align-items-center w-full">
                    <div className="mr-1" style={{width: '5rem'}}>Poznámka ošetrovňa</div>
                    <XInputTextareaBase ref={xInputTextareaBasePoznamkaOsetrovnaRef}
                                        value={zapis.klientSluzba ? zapis.klientSluzba.poznamkaOsetrovna : null}
                                        onChange={(value: string | null) => {
                                            zapis.klientSluzba!.poznamkaOsetrovna = value;
                                            props.onChangeZapis();
                                        }}
                                        readOnly={zapis.klientSluzba === null}
                                        style={{width: '100%'}} autoResize={true}/>
                </div> : null
            }
        </div>;

    if (!zapis.xFieldSetBaseRef) {
        zapis.xFieldSetBaseRef = React.createRef<XFieldSetBase>();
    }

    const specPoradViewStatus: XViewStatus = zistiViewStatusPrePravoKod(SluzbaRolaPravaKod.zapisPanelSpecPorad);
    const psychPoradViewStatus: XViewStatus = zistiViewStatusPrePravoKod(SluzbaRolaPravaKod.zapisPanelPsychPorad);

    // className="m-1" - medzera medzi panelmi
    return (
        <Panel key={zapis.id} header={header} className="m-1" pt={{title: {style: {width: '100%'}}}} toggleable>
            <XFieldSetBase ref={zapis.xFieldSetBaseRef} values={zapis.atributy}
                           onFieldChange={(field: string, value: any) => onFieldChangeZapis(zapis, field, value)}
                           fieldSetId="zapis" fieldProps={fieldProps}
                           fieldViewStatus={(xFieldMeta: XFieldMeta) => fieldViewStatusZapis(zapis, xFieldMeta)}
                           fieldElemProps={(xFieldMeta: XFieldMeta) => fieldElemPropsZapis(zapis, xFieldMeta)}
                           canEdit={Utils.isUserAdmin()}
                           onEditFieldChange={(xFieldMeta: XFieldMeta) => props.onEditFieldChange()}/>
            {specPoradViewStatus !== XViewStatus.Hidden ?
                <div key="specPorad" className="flex align-items-center">
                    <div style={{width: '12rem'}}>Špecializované porad. čas</div>
                    <XInputIntervalBase value={zapis.specPoradCas}
                                        onChange={(value: IPostgresInterval | null) => {
                                            zapis.specPoradCas = value;
                                            props.onChangeZapis();
                                        }}
                                        readOnly={specPoradViewStatus === XViewStatus.ReadOnly}/>
                    <div className="mx-1">Pracovník</div>
                    <XAutoCompleteBase value={zapis.specPoradXUser}
                                       suggestions={getPoradXUserSuggestions(props.vykazStreetwork, props.userSpecPoradList)}
                                       field="name"
                                       onChange={(object: XUserDePaul, objectChange: OperationType) => onChangeSpecPoradXUser(zapis, object)}
                                       onErrorChange={(error: (string | undefined)) => onErrorChangeSpecPoradXUser(zapis, error)}
                                       width={'12rem'}
                                       readOnly={specPoradViewStatus === XViewStatus.ReadOnly}/>
                    {zapis.sluzba.kod === SluzbaEnum.streetwork ?
                        <XAutoCompleteBase value={zapis.specPoradXUser2}
                                           suggestions={getPoradXUserSuggestions(props.vykazStreetwork, props.userSpecPoradList)}
                                           field="name"
                                           onChange={(object: XUserDePaul, objectChange: OperationType) => onChangeSpecPoradXUser2(zapis, object)}
                                           onErrorChange={(error: (string | undefined)) => onErrorChangeSpecPoradXUser2(zapis, error)}
                                           width={'12rem'}
                                           readOnly={specPoradViewStatus === XViewStatus.ReadOnly}/>
                    : null}
                    <XInputTextareaBase value={zapis.specPoradZapis}
                                        onChange={(value: string | null) => {
                                            zapis.specPoradZapis = value;
                                            props.onChangeZapis();
                                        }}
                                        style={{width: `calc(100% - ${32 + (zapis.sluzba.kod === SluzbaEnum.streetwork ? 12 : 0)}rem)`}} rows={1}
                                        autoResize={true}
                                        readOnly={specPoradViewStatus === XViewStatus.ReadOnly}/> {/* 12 + 3.5 + 4.5 + 12 (+ 12) = 32 (+ 12) (sirka atributov a labelov pred tymto atributom) */}
                </div> : null
            }
            {psychPoradViewStatus !== XViewStatus.Hidden ?
                <div key="psychPorad" className="flex align-items-center">
                    <div style={{width: '12rem'}}>Psychologické porad. čas</div>
                    <XInputIntervalBase value={zapis.psychPoradCas}
                                        onChange={(value: IPostgresInterval | null) => {
                                            zapis.psychPoradCas = value;
                                            props.onChangeZapis();
                                        }}
                                        readOnly={psychPoradViewStatus === XViewStatus.ReadOnly}/>
                    <div className="mx-1">Pracovník</div>
                    <XAutoCompleteBase value={zapis.psychPoradXUser}
                                       suggestions={getPoradXUserSuggestions(props.vykazStreetwork, props.userPsychPoradList)}
                                       field="name"
                                       onChange={(object: XUserDePaul, objectChange: OperationType) => onChangePsychPoradXUser(zapis, object)}
                                       onErrorChange={(error: (string | undefined)) => onErrorChangePsychPoradXUser(zapis, error)}
                                       width={'12rem'}
                                       readOnly={psychPoradViewStatus === XViewStatus.ReadOnly}/>
                </div> : null
            }
            <div key="vseobecnyZapis">
                <XEditorBase value={zapis.vseobecnyZapis} onChange={(value: string | null) => {zapis.vseobecnyZapis = value; props.onChangeZapis();}}
                         style={{width: '100%'}}/>
            </div>
        </Panel>
    );
}

// namiesto statickej funkcie
ZapisPanel.vytvorAtributyHistoria = async (zapis: Zapis) => {

    if (zapis.klientSluzba === null || zapis.datum === null) {
        zapis.atributyHistoria = {}; // ak klient alebo datum nie je (este) vyplneny, neratame nic
        return;
    }

    if (!zapis.xFieldSetBaseRef) {
        throw 'Unexpected error - zapis.xFieldSetBaseRef is undefined';
    }

    if (!zapis.xFieldSetBaseRef.current) {
        throw 'Unexpected error - zapis.xFieldSetBaseRef.current is undefined';
    }

    const xFieldSetBase: XFieldSetBase = zapis.xFieldSetBaseRef.current!;
    // historia sa rata len pre fieldy ktore su editovatelne (ReadWrite), setrime tym performance (este by davalo zmysel dat level ReadOnly)
    const xFieldMetaList: XFieldMeta[] = xFieldSetBase.getFieldListForFieldProp(FieldPropId.historia, XViewStatus.ReadWrite);

    const atributInfoList: AtributInfo[] = [];
    for (const xFieldMeta of xFieldMetaList) {
        // pocetDni by mal byt vzdy vyplneny (odfiltrovali sme atributy s undefined)
        const pocetDni: number = HistoriaFieldPropEdit.getFieldPropValue(xFieldMeta, FieldPropId.historia)!;
        atributInfoList.push({field: xFieldMeta.field, pocetDni: pocetDni});
    }
    const atributyHistoriaRequest: AtributyHistoriaRequest = {zapisId: zapis.id, klientSluzbaId: zapis.klientSluzba.id, datum: zapis.datum, atributInfoList: atributInfoList};
    //setLoading(true);
    const atributyHistoriaResponse: AtributyHistoriaResponse = await XUtils.fetchOne('zapisy-atributy-historia', atributyHistoriaRequest);
    //setLoading(false);

    zapis.atributyHistoria = atributyHistoriaResponse;
}

// namiesto statickej funkcie
ZapisPanel.validateZapis = (vykazStreetwork: VykazStreetwork | null | undefined, zapis: Zapis): XErrors => {
    const errors: XErrors = {};

    if (zapis.klientSluzbaError) {
        errors.klientSluzba = zapis.klientSluzbaError;
    }

    if (zapis.specPoradXUserError) {
        errors.specPoradXUser = zapis.specPoradXUserError;
    }

    if (zapis.specPoradXUser2Error) {
        errors.specPoradXUser2 = zapis.specPoradXUser2Error;
    }

    if (zapis.psychPoradXUserError) {
        errors.psychPoradXUser = zapis.psychPoradXUserError;
    }

    if (zapis.sluzba.kod === SluzbaEnum.lujzaPoradenstvo && !zapis.lujzaPoradProjekt) {
        errors.lujzaPoradProjekt = 'Projekt musí byť vyplnený.';
    }

    if (zapis.sluzba.kod === SluzbaEnum.vincentPoradenstvo && !zapis.vincentPoradProjekt) {
        errors.vincentPoradProjekt = 'Projekt musí byť vyplnený.';
    }

    // trojica (klikacka Špecializované poradenstvo, klikacka Základné poradenstvo, udaje Špecializované porad. čas/Pracovník)
    // -> max 1 z toho moze byt pouzite
    let pouzitePocet: number = 0;
    const klikackaSpecPorad: boolean = ZapisPanel.getCheckboxValue(zapis, "specializovanepoadenstvo");
    if (klikackaSpecPorad) {
        pouzitePocet++;
    }
    if (ZapisPanel.getCheckboxValue(zapis, "zakladneporadenstvo")) {
        pouzitePocet++;
    }
    if (zapis.specPoradCas || zapis.specPoradXUser || zapis.specPoradXUser2 || zapis.specPoradZapis) {
        pouzitePocet++;
    }

    if (pouzitePocet > 1) {
        errors.atributy = 'Trojica "Špecializované poradenstvo" (klikačka), "Základné poradenstvo" (klikačka), "Špecializované porad. čas/Pracovník/zápis vedľa" - maximálne jedna vec z tejto trojice môže byť použitá.';
    }

    const xFieldSetBase: XFieldSetBase = zapis.xFieldSetBaseRef!.current!;

    if (xFieldSetBase.hasFieldOfFieldPropSomeValue(FieldPropId.specPorad, XViewStatus.ReadOnly)) {
        // bud je zakliknuta klikacka Spec.porad. alebo su vyplnene specPoradCas a specPoradXUser
        if (!klikackaSpecPorad) {
            if (!zapis.specPoradCas) {
                if (zapis.specPoradXUser || zapis.specPoradXUser2) {
                    // ak je vyplneny user, tak nech vyplni aj specPoradCas
                    errors.specPoradCas = 'Špecializované poradenstvo - čas musí byť vyplnený.';
                }
                else {
                    errors.specPoradCas = 'Špecializované poradenstvo - čas musí byť vyplnený (alebo klikačka "Špecializované poradenstvo" musí byť vyplnená).';
                }
            }
            if (!zapis.specPoradXUser) {
                if (zapis.specPoradCas) {
                    // ak je vyplneny specPoradCas, tak nech vyplni aj usera
                    errors.specPoradXUser = 'Pracovník ktorý poskytol špecializované poradenstvo musí byť vyplnený.';
                }
                else {
                    errors.specPoradXUser = 'Pracovník ktorý poskytol špecializované poradenstvo musí byť vyplnený (alebo klikačka "Špecializované poradenstvo" musí byť vyplnená).';
                }
            }
        }
    }

    if (xFieldSetBase.hasFieldOfFieldPropSomeValue(FieldPropId.psychPorad, XViewStatus.ReadOnly)) {
        if (!zapis.psychPoradCas) {
            errors.specPoradCas = "Psychologické poradenstvo - čas musí byť vyplnený."
        }
        if (!zapis.psychPoradXUser) {
            errors.psychPoradXUser = "Pracovník ktorý poskytol psychologické poradenstvo musí byť vyplnený."
        }
    }

    if (vykazStreetwork) {
        if (zapis.specPoradXUser && !ZapisPanel.userJeNaVykaze(vykazStreetwork, zapis.specPoradXUser)) {
            errors.specPoradXUser = `Pracovník ktorý poskytol špecializované poradenstvo (${zapis.specPoradXUser.name}) sa nenachádza v zozname pracovníkov výkazu.`;
        }

        if (zapis.specPoradXUser2 && !ZapisPanel.userJeNaVykaze(vykazStreetwork, zapis.specPoradXUser2)) {
            errors.specPoradXUser2 = `Pracovník ktorý poskytol špecializované poradenstvo (${zapis.specPoradXUser2.name}) sa nenachádza v zozname pracovníkov výkazu.`;
        }

        if (zapis.psychPoradXUser && !ZapisPanel.userJeNaVykaze(vykazStreetwork, zapis.psychPoradXUser)) {
            errors.psychPoradXUser = `Pracovník ktorý poskytol psychologické poradenstvo (${zapis.psychPoradXUser.name}) sa nenachádza v zozname pracovníkov výkazu.`;
        }
    }

    return errors;
}

// pomocna funkcia
ZapisPanel.getCheckboxValue = (zapis: Zapis, field: string): boolean => {
    return (zapis.atributy as XFieldSetValues)[field] ?? false;
}

ZapisPanel.userJeNaVykaze = (vykazStreetwork: VykazStreetwork, xUser: XUserDePaul): boolean => {
    return XUtilsCommon.arrayIncludes(vykazStreetwork.vykazXUserList.map((vykazXUser: VykazXUser) => vykazXUser.xUser), xUser, 'id');
}
